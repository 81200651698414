.is-visible, grid__filterResult--is-visible {
    max-height: 30em;
    transition: max-height 1s ease;
    visibility: visible;
}

.is-hidden {
    max-height: 0;
    transition: max-height 1s ease;
    visibility: hidden;
}

.header__searchBloc--is-visible {
    max-width: 100%;
    transition: max-width 1s ease;
}

.filters__choice--is-visible {
    max-height: 200px;
    transition: max-height 1s ease;
    visibility: visible;
    overflow-y: auto;
}

.o80 {
    opacity: .8;
}

.sub-menu {
    overflow: hidden;
    max-height: 0;

    .paragraphe__text {
        margin: .5em 2em 0;
    }
}

.accordion {
    margin: 0 0 2em 1em;
}

.accordion li:target > .sub-menu {
    max-height: 400px;
    transition: max-height 1s ease-in;
}