.footer {
    background-color: black;
    /* position : fixed;
    bottom: 0;
    height: 20%;
    width: 100%; */
    &__medflixs {
        padding: 1.5em;
        @media(min-width: 48em) {
            padding: 1.5em 0;
        }
    }
    &__menu {
        li {
            @media(min-width: 48em) {
                display: inline-block;
                &::after {
                    content: "|";
                    display: inline-block;
                    margin: 0 .2em;
                }
                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    &__copyright {
        opacity: .5;
        padding-bottom: 2em;
        text-align: center;
        p {
            color: white;
        }
    }
    &__medflixsSocial,
    &__cherry {
        @media(min-width: 48em) {
            display: inline-block;
            vertical-align: middle;
        }
    }
    &__medflixs {
        &Social {
            &Link {
                display: inline-block;
                height: 1.5em;
                padding: 1em;
                width: 1.5em;
            }
            &Icon {
                height: 1.5em;
                vertical-align: middle;
            }
        }
        &Logos {
            min-height: 4em;
            max-width: 12em;
            width: 100%;
        }
    }
    &__cherry {
        &Logo {
            height: 3.75em;
            width: 6.25em;
            @media(min-width: 48em) {
                margin-left: 2em;
            }
        }
    }
    #loader {
        display: none;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 3;
        img {
            width: 100px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.return-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: rgb(var(--rouge-moyen));
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    i {
        margin-top: 4px;
        margin-left: 10px;
    }
}

.return-top:hover {
    bottom: 25px;
}