.more {
    display: flex;
    justify-content: center;
    margin: 1em auto;

    &__link {
    /*    background: resolve(btn-more.svg) center no-repeat; */
        height: 2.5em;
        width: 2.5em;
        cursor: pointer;

        span {
            display: inline-block;
            text-indent: -9999px;
        }
    }
}

.bt {
	background-color: rgb(var(--rouge-fonce));
    border: none;
/*     border-radius: .25em; */
    cursor: pointer;
    display: inline-block;
    margin: 0 .5em;
    padding: .3em 1em;

    &:hover {
        background-color: rgb(var(--rouge-tresFonce));
    }
}