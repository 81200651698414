.sidebar {
    &__widget {
        /* background-color: rgb(var(--beige)); */
        background-color: rgb(var(--blanc));
        padding: 1em;
        @media(min-width: 30em) {
            &:not(.widget__twitter) {
                flex: 0 0 calc(50% - 2.5em);
                margin: auto;
                max-width: 20em;
            }
        }
    }
    &__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
    }
    &__img {
        margin-right: 1em;
        max-width: 5.25em;
        min-width: 3em;
        &--kol {
            clip-path: circle(50%);
            vertical-align: middle;
        }
    }
    &__text,
    &__pictos {
        font-size: .75em;
    }
    /* &__pictos {
        span {
            clip: rect(1px, 1px, 1px, 1px);
            height: 1px;
            overflow: hidden;
            position: absolute;
            width: 1px;
        }
    } */
}

.widget {
    &__twitter {
        background-color: white;
        margin-top: 2.5em;
        @media(min-width: 30em) {
            flex: 1 0 100%;
        }
        @media(min-width:48em) {
            padding: 0;
        }
    }
}

.twitter-timeline {
    background-color: white;
}