body {
    background-color: black;
    margin: 0 auto;
}

.wrapper {
    background-color: white;
}

a {
    color: inherit;
    text-decoration: none;
}

sup,
sub {
    line-height: 0;
}

ul,
ol,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

img,
video {
    height: auto;
    max-width: 100%;
    width: 100%;
}

#my-video {
    height: auto !important;
    width: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

hr {
    background-color: rgb(var(--rouge-fonce));
    border: 0;
    height: .0625em;
    margin: 1em 0;
}

.fl {
    float: left
}

.fr {
    float: right
}