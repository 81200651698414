.header {
    video {
        height: 0;
        visibilty: hidden;
    }
    @media(min-width:48em) {
        video {
            height: auto;
            left: 0;
            position: absolute;
            opacity: .5;
            top: -10em;
            visibility: visible;
            /* z-index: -100; */
        }
    }
    &__logo {
        max-width: 40%;
        width: 11.25em;
        svg {
            height: 85px;
            width: 100%;
        }
    }
    &__menu {
        text-align: left;
        @media(min-width: 48em) {
            text-align: center;
        }
        &Hover {
            padding: .5em .75em;
            &:hover {
                background-color: rgb(var(--rouge-tresFonce));
            }
            >a {
                border: 0;
                border-bottom: .25em solid transparent;
            }
        }
        &Secondaire {
            /* background-color: rgb(var(--background-menu-secondaire)); */
            background-color: rgb(var(--rouge-fonce));
            transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            z-index: 1;
        }
    }
    &__login,
    &__registration,
    &__langFr,
    &__langEn {
        display: inline-block;
        font-size: .75em;
        margin: 0 .1em;
        padding: .5em 1em;
    }
    &__langFr,
    &__langEn {
        margin-right: 0;
        &:hover {
            text-decoration: underline;
        }
    }
    &__langEn {
        padding-right: 0;
    }
    &__langFr {
        padding-left: 0;
    }
    &__banner {
        display: none;
        @media(min-width: 80em) {
            align-items: center;
            background-color: rgba(var(--blanc), .4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            position: relative;
        }
    }
    &__navigation {
        /* background: linear-gradient(to right, rgb(231,17,30) 0%, rgb(var(--rouge-fonce)) 100%); */
        background: rgb(var(--rouge-fonce));
        box-sizing: border-box;
        left: 0;
        min-height: 7.5em;
        padding: 0 1.5rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        transition: all 0.3s ease-in-out;
        &Btn {
            background-color: transparent;
            border: none;
            cursor: pointer;
            max-width: 4em;
            min-width: 2em;
            width: 10%;
        }
    }
    &__title {
        /*     	background-color: rgba(var(--gris-moyen), .4); */
        padding: .5em;
        width: calc(100% - 1em);
    }
    &__lang {
        /* @media(min-width:48em) {
            background: linear-gradient(to right, rgb(var(--rouge-tresFonce)) 0%, rgb(var(--rouge-fonce)) 150%);
            border-radius: 100%;
            height: 2em;
            padding: .5em;
            width: 2em;

            > a::after {
                background: resolve(btn-arrow-down.svg) bottom center no-repeat;
                content: "";
                display: inline-block;
                height: 1.2em;
                margin-left: 1.5em;
                margin-top: .25em;
                position: absolute;
                top: 0;
                width: 1.2em;
            }
        } */
        &Btn {}
        span {
            display: inline-block;
            text-indent: -9999px;
        }
        &Img {
            width: 2em;
        }
        &Choice {
            display: inline-block;
            @media(min-width: 48em) {
                background: linear-gradient(to right, rgb(var(--rouge-moyen)) 0%, rgb(var(--rouge-fonce)) 100%);
                display: block;
                margin-left: -1em;
                max-height: 0;
                overflow: hidden;
                padding: .5em;
                position: absolute;
                visibility: hidden;
                width: 4em;
            }
        }
        /* &Fr, &En {
            border-radius: 100%;
            display: inline-block;
            height: .5em;
            padding: .5em;
            position: relative;
            vertical-align: middle;
            width: .5em;
        } */
        /* &Fr {
            background: resolve(btn-flag-fr.svg) center no-repeat;
        }

        &En {
            background: resolve(btn-flag-en.svg) center no-repeat;
        } */
    }
    &__register {
        background: resolve(btn-register.svg) center no-repeat;
        /* background-size: contain;
        display: inline-block; */
        margin: 5% 0;
        padding-left: 3em;
        width: 14em;
        /* z-index: 1;
        position: relative; */
    }
    &__connexion {
        background: resolve(user.svg) center no-repeat;
        cursor: pointer;
        height: 1.5em;
        width: 1.5em;
        &Bloc {
            max-height: 0;
            overflow: hidden;
            &.is-visible {
                margin-top: 2em;
                overflow: visible;
            }
        }
        @media(min-width:48em) {
            background-image: none;
            width: auto;
            height: auto;
            &Bloc {
                max-height: none;
                &.is-visible {
                    margin-top: 0;
                }
            }
        }
    }
    &__login,
    &__registration {
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: rgb(var(--rouge-tresFonce));
        }
        @media(min-width:48em) {
            /*             background: linear-gradient(to right, rgb(var(--rouge-moyen)) 0%, rgb(var(--rouge-fonce)) 100%); */
            /*             border-radius: 100%; */
            /*             padding: 0 1em; */
        }
    }
    &__search {
        margin-top: 1em;
        @media(min-width: 48em) {
            background: linear-gradient(to right, rgb(var(--rouge-tresFonce)) 0%, rgb(var(--rouge-fonce)) 150%);
            /*             border-radius: 100%; */
            height: 2em;
            margin-top: 0;
            padding: .5em;
            /*             position: relative; */
            width: 2em;
        }
        &Btn {
            display: none;
            @media(min-width: 48em) {
                background: resolve(btn-search.svg) center no-repeat;
                border: none;
                cursor: pointer;
                display: inline-block;
                height: 2em;
                margin: auto;
                padding: 0;
                width: 2em;
            }
            &.open {
                background: resolve(btn-close.svg) center no-repeat;
                width: 1.3em;
            }
            &Img {
                height: 2em;
                width: 2em;
            }
        }
        &Form {
            margin: .5em 0;
            max-width: 15em;
            width: 100%;
            label {
                position: absolute;
                top: -9999px;
            }
            input {
                background-color: rgb(var(--beige));
                border-color: transparent;
                border-bottom: .025em solid white;
                /* color: white; */
                height: 1.5em;
                vertical-align: top;
                width: 80%;
            }
        }
        &Submit {
            /*             background: resolve(btn-search.svg) center no-repeat, */
            /*                         linear-gradient(to right, rgb(var(--rouge-tresFonce)) 0%, rgb(var(--rouge-fonce)) 150%); */
            background: transparent;
            border: none;
            /*             border-radius: 100%; */
            color: white;
            cursor: pointer;
            /*             height: 2em; */
            /*             padding: 0; */
            /*             width: 2em; */
        }
        &Bloc {
            @media(min-width: 48em) {
                height: 2em;
                max-width: 0;
                overflow: hidden;
                position: absolute;
                right: 6em;
                width: 30em;
            }
        }
    }
    &__account {
        &Btn {
            text-align: left;
            @media(min-width: 48em) {}
            span {
                text-transform: capitalize;
            }
            ul.header__accountBloc {
                @media(max-width: 48em) {
                    display: block;
                    /*                     background-color: rgb(231,17,30); */
                    position: fixed;
                    top: 29px;
                    left: 0;
                    transform: translateX(-100%);
                    transition: all 0.3s ease-in-out;
                    z-index: 2;
                }
            }
            ul.header__accountBloc.is-visible {
                @media(max-width: 48em) {
                    transform: translateX(0);
                }
            }
            ul.header__accountBloc.is-visible li {
                @media(max-width: 48em) {
                    display: block;
                }
            }
            &::after {
                background: resolve(btn-arrow-down.svg) bottom center no-repeat;
                content: "";
                display: inline-block;
                height: 1em;
                margin-left: .2em;
                width: 1em;
            }
        }
        &Bloc {
            /* margin-left: 1.5em; */
            background: rgb(var(--gris-noir));
            text-align: left;
            white-space: nowrap;
            @media(min-width: 48em) {
                max-height: 0;
                overflow: hidden;
                margin-left: 0;
                padding: .5em 1em;
                position: absolute;
                visibility: hidden;
                z-index: 1;
                justify-content: flex-start;
                transform: translateX(-66%);
            }
            li {
                &::before {
                    content: "\2192";
                    display: inline-block;
                    margin-right: .5em;
                }
                @media(min-width:48em) {
                    /* &:not(:first-child) {
                	   margin-left: 2em;
                    } */
                    &::before {
                        content: "";
                        height: 1em;
                        vertical-align: middle;
                        width: 1em;
                    }
                    &.profile::before {
                        background: resolve(user.svg) center no-repeat;
                    }
                    &.admin::before {
                        background: resolve(settings-work-tool.svg) center no-repeat;
                    }
                    &.upload::before {
                        background: resolve(addition-sign.svg) center no-repeat;
                    }
                    &.logout::before {
                        background: resolve(power-button.svg) center no-repeat;
                    }
                }
            }
        }
    }
    &__video {
        &Btn {
            @media(min-width: 48em) {
                &::after {
                    background: resolve(btn-arrow-down.svg) bottom center no-repeat;
                    content: "";
                    display: inline-block;
                    height: 1em;
                    margin-left: .2em;
                    vertical-align: sub;
                    width: 1em;
                }
            }
        }
        &Bloc {
            /* margin-left: 3.5em; */
            text-align: left;
            @media(min-width: 48em) {
                background: linear-gradient(to right, rgb(var(--rouge-moyen)) 0%, rgb(var(--rouge-fonce)) 100%);
                max-height: 0;
                margin-left: 0;
                overflow: hidden;
                padding: .5em 1em;
                position: absolute;
                visibility: hidden;
                z-index: 1;
            }
            li {
                &::before {
                    content: "\2192";
                    display: inline-block;
                    margin-right: .5em;
                }
                @media(min-width:48em) {
                    &::before {
                        content: "";
                        height: 1.5em;
                        vertical-align: middle;
                        width: 1.5em;
                    }
                    &:nth-child(1)::before {
                        background: resolve(icon-congres.svg) center no-repeat;
                    }
                    &:nth-child(2)::before {
                        background: resolve(icon-symposium.svg) center no-repeat;
                    }
                }
            }
        }
    }
    &--fixed {
        box-shadow: .0625em .0625em .5em black;
        top: -30px;
        .header__menuSecondaire {
            transform: scaleY(0);
        }
    }
}


/* #wrapper{
    padding-top: 175px;

    @media (min-width: 80em){
        padding-top: 120px;
    }

    @media screen and (max-width: 768px){
        padding-top: 127px;
    }
} */