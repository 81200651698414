.presentation {
	&__container {
		display: none;

        @media(min-width:48em) {
            background: rgb(var(--beige));
        	display: block;
        }
	}

	&__item {
        @media(min-width:48em) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1em;
        }

        @media(min-width:80em) {
            flex-direction: row;
        }
	}

	&__title, &__text {
		display: none;
	}

	&__title {
	   @media(min-width:48em) {
           display: block;
       }
	}

	&__text {
	   @media(min-width:80em) {
           display: block;
       }
	}

    &__icon {
    	&:first-child {
            height: 3.34375em;
            width: 3.5625em;
        }

    	&:nth-child(2) {
            height: 4.375em;
            width: 3.8125em;
        }

    	&:nth-child(3) {
            height: 3.625em;
            width: 3.5625em;
        }
    }

    @media(min-width:80em) {
        max-width: 62em;
        margin: 0 auto;

        &__title, &__text {
            padding-left: 1em;
        }
    }
}