html,
body {
    height: 100%;
}

.wrapper {
    padding-top: 7em;
    
    &--login {
        display: flex;
        flex-direction: column;
        height: calc(100% - 7.5em);
        justify-content: space-between;
    }
}

.main {
    margin: 0 auto;
    max-width: 75em;
}

.main__content.full {
    width: 100%;
}

.header {
    position: relative;
    &__navigation {
        align-items: center;
        display: flex;
        flex-direction: column;
        &Bloc {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
    &__navigation {
        &Btn {
            position: absolute;
            right: 1em;
            top: .8em;
        }
    }
    &__menu {
        align-items: center;
        display: flex;
        flex-direction: column;
        letter-spacing: .08em;
        max-height: 0;
        overflow: hidden;
        text-transform: uppercase;
        width: calc(100%);
        &Principal {
            align-items: center;
            display: flex;
            flex-direction: column;
            /* max-width: 75em; */
            max-width: calc(120em);
            position: relative;
            width: 100%;
        }
        &Secondaire {
            background-color: rgb(var(--rouge-moyen));
            display: flex;
            justify-content: center;
            /* padding: 0 1em; */
            width: 100%;
            box-sizing: border-box;
            >ul {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
    &--fixed {
        position: fixed;
        width: 100%;
        z-index: 10;
    }
}

.presentation {
    align-items: center;
    display: flex;
    flex-flow: column;
    width: 100%;
    &__item,
    &__item__dashboard,
    &__item__dashboard__video {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        max-width: 18em;
        width: calc(100% - 2em);
    }
    &__text {
        display: inline-block;
        max-width: 12em;
    }
}

.grid {
    margin: 2.5em 0;
    &__title {
        &::after {
            clear: both;
            content: "";
            display: table;
        }
    }
    &__bloc,
    &__bloc__playlist,
    &__bloc__dashboard {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 1.5em;
    }
    &__bloc__dashboard__video {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 0em;
    }
    &__item,
    &__item__dashboard,
    &__author,
    &__item__dashboard__video {
        width: 100%;
    }
    &__item,
    &__item__dashboard {
        margin-bottom: 2em;
    }
    &__item__dashboard__video {
        margin: 0em;
    }
    &__item__dashboard {
        background-color: #fbf4eb;
        svg {
            float: right;
            padding: 0.7em;
            padding-top: 1.2em;
        }
    }
    &__item__dashboard__video {
        background-color: #fbf4eb;
        svg {
            padding: 0.5em;
            float: left;
        }
    }
    &__author {
        margin: 1em auto;
    }
}

.author {
    &__edit {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__validation {
        width: 100%;
    }
}

.footer {
    text-align: center;
}

@media(min-width:30em) {
    .main__sidebar {
        display: flex;
        flex-direction: column;
        /* justify-content: space-evenly; */
    }
    .sidebar {
        &__widget {
            box-sizing: border-box;
            overflow: hidden;
            /* &:first-child {
                height: 90%;
            } */
            .twitter-timeline {
                height: 95% !important;
                margin-top: 1em !important;
                min-height: 40em !important;
                @media (max-width: 47.9375em) {
                    margin-left: 25% !important;
                    width: 50% !important;
                }
            }
        }
    }
    .presentation {
        align-items: center;
        display: flex;
        justify-content: space-around;
    }
    .grid {
        &__bloc {
            align-items: flex-start;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
        }
        &__item {
            width: calc(50% - 1em);
            &:not(:nth-child(2n)) {
                margin-right: 2em;
            }
        }
        &__authorEdit {
            align-items: stretch;
            justify-content: space-between;
        }
    }
}

@media(min-width:48em) {
    .wrapper {
        padding-top: 7.5em;
    }
    .header {
        &__logo {
            margin: 0 1em;
        }
        &__navigation {
            justify-content: center;
            &Bloc {
                align-items: flex-end;
                max-width: 58em;
                width: auto;
            }
            &Btn {
                display: none;
            }
        }
        &__banner {
            min-height: 16em;
        }
        &__menu {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            max-height: none;
            &Principal {
                align-items: flex-end;
                flex-direction: row;
                justify-content: space-between;
                &--center {
                    justify-content: center;
                }
            }
            &Secondaire {
                >ul {
                    align-items: center;
                    justify-content: flex-end;
                    /* max-width: 75em; */
                }
            }
        }
    }
    .presentation {
        flex-flow: row;
        width: 100%;
        &__item,
        &__item__dashboard,
        &__item__dashboard__video {
            width: 100%;
        }
    }
    .main {
        display: flex;
        flex-flow: row wrap;
        &__content {
            width: calc(70%);
        }
        &__sidebar {
            display: block;
            margin-top: 2.5em;
            margin-right: 1em;
            width: calc(30% - 1em);
        }
    }
    .grid {
        &__description {
            align-items: center;
            display: flex;
            justify-content: space-between;
            >figure {
                margin: 0;
                text-align: center;
                width: calc(25% - 1em);
                img {
                    max-height: 12.5em;
                    width: auto;
                }
            }
            >div {
                width: calc(75% - 1em);
            }
        }
        &__pubTweet {
            display: flex;
            .publication {
                margin-right: 2em;
                width: 70%;
            }
            .tweeter__widget {
                width: 30%;
            }
            .col3 {
                width: 33%;
            }
        }
        &__kol {
            display: flex;
            .option {
                margin-right: 2em;
                width: 50%;
            }
            .new {
                width: 50%;
            }
        }
    }
    .author {
        &__presentation {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        &__thumbnail {
            flex: 0 1;
        }
        &__detail {
            flex: 1 2;
        }
        &__cursus {
            flex: 1 1 auto;
        }
        &__edit {
            width: calc(100%/2 - 2em);
            &:nth-child(1) {
                padding: 1em 2em 1em 0;
            }
            &:nth-child(2) {
                padding: 1em 0 1em 1em;
            }
        }
    }
    .footer {
        &__medflixs {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: auto;
            max-width: 120rem;
            width: calc(100% - 5rem);
        }
    }
}

@media(min-width:65.5em) {
    .author {
        &__cursus {
            max-width: 20%;
        }
    }
}

@media(min-width:80em) {
    .header {
        &__menu {
            justify-content: flex-end;
            &Hover {
                margin: 0 0 0 2em;
            }
        }
        &__search {
            &Form {
                text-align: right;
            }
        }
    }
    .main {
        &__content {
            width: calc(80%);
        }
        &__sidebar {
            margin-right: 0;
            width: calc(20% - 1em);
        }
    }
    .grid {
        &__item,
        &__item__dashboard,
        &__item__dashboard__video {
            width: calc(100%/3 - 1.5em);
            &:nth-child(2n) {
                margin-right: 2em;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        &__header {
            &--form {
                display: flex;
                justify-content: space-between;
            }
        }
        &__pubTweet {
            .publication {
                width: 80%;
            }
            .tweeter__widget {
                width: 20%;
            }
        }
    }
    .event {
        &__item,
        &__item__dashboard,
        &__item__dashboard__video {
            width: calc(100%/2 - 1em);
        }
    }
}

@media(min-width:100em) {
    .fieldset {
        &.fieldset--agenda {
            max-width: 100%;
            width: 100%;
        }
    }
    .search {
        &__form {
            display: flex;
            justify-content: space-around;
        }
    }
}

body.reversed #main {
    @media (min-width: 80em) {
        .grid {
            padding-left: 2em;
            padding-right: 0;
        }
    }
    .main__sidebar {
        padding: 1em;
        box-sizing: border-box;
        background-color: rgb(251, 244, 235);
        margin-top: -20px;
        margin-right: 1em;
        @media screen and (max-width: 767px) {
            margin-top: 0;
        }
    }
}