/* Open Sans 400 regular */

@font-face {
    font-family: 'Open Sans';
    font-display: auto;
    src: local('Open Sans'), url('../webfonts/OpenSans-Regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}


/* Open Sans 400 italic */

@font-face {
    font-family: 'Open Sans';
    font-display: auto;
    src: local('Open Sans Italic'), url('../webfonts/OpenSans-Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}


/* Open Sans 600 semibold */

@font-face {
    font-family: 'Open Sans';
    font-display: auto;
    src: local('Open Sans Semibold'), url('../webfonts/OpenSans-Semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


/* Open Sans 700 bold */

@font-face {
    font-family: 'Open Sans';
    font-display: auto;
    src: local('Open Sans Bold'), url('../webfonts/OpenSans-Bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


/* Font Awesome 400 regular */

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff");
}

.far {
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
}

.fa-star::before {
    content: "\f005";
}


/* Font Awesome 900 solid */

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-display: auto;
    font-style: normal;
    font-weight: 900;
    src: url("../webfonts/fa-solid-900.woff2") format("woff2"), url("../webfonts/fa-solid-900.woff") format("woff");
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.fa-eye::before {
    content: "\f06e";
}

.fa-comments::before {
    content: "\f086";
}

.fa-lock-open::before {
    content: "\f3c1";
}

.fa-lock::before {
    content: "\f023";
}

.fa-cog::before {
    content: "\f013";
}

.fa,
.far,
.fas {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -.0667em;
}

body {
    color: rgb(var(--gris-moyen));
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

h1 {
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h3 {
    font-size: 1.75em;
}

hr {
    color: rgba(var(--rouge-moyen));
}

.link {
    text-decoration: underline;
}

.header {
    color: white;
    text-align: center;
    &__title {
        font-size: 2.5em;
        z-index: 1;
    }
    &__register {
        color: rgb(var(--rouge-moyen));
        font-family: 'myriad-pro', sans-serif;
        font-size: 1.375em;
        font-weight: bold;
        line-height: 4;
    }
    &__menu {
        color: rgb(var(--blanc));
        font-size: .75em;
        line-height: 1.75;
    }
    &__logo {
        color: rgb(var(--blanc));
    }
    &__account,
    &__video {
        &Btn {
            cursor: pointer;
            font-size: .875em;
        }
        &Bloc {
            @media(min-width: 48em) {
                line-height: 2;
            }
        }
    }
}

.presentation {
    color: black;
    text-align: left;
    &__title {
        font-size: 1.0625em;
        font-weight: bold;
        text-transform: uppercase;
    }
    &__text {
        font-size: .875em;
    }
}

.fieldset {
    font-size: .75em;
    &__header {
        text-align: center;
    }
    &__logo {}
    &__title {
        color: rgba(var(--rouge-moyen));
        font-size: 1.625em;
    }
    &__paragraphe {
        line-height: 1.5;
        text-align: justify;
    }
    &__form {
        &Label {
            text-transform: uppercase;
        }
        &Group {
            text-align: left;
        }
        &Bt {
            text-align: center;
        }
    }
    input,
    textarea {
        color: rgb(var(--gris-moyen));
    }
}

.grid {
    &__title {
        color: rgb(var(--gris-noir));
        font-size: 1.063em;
        text-transform: uppercase;
    }
    &__item {
        &Detail {}
        &Text {
            font-size: .75em;
        }
        &Title {
            color: white;
            font-size: .75em;
            font-weight: 400;
            letter-spacing: .08em;
            line-height: 1.6;
            overflow: hidden;
            text-transform: uppercase;
            span {
                font-size: .7em;
                text-transform: initial;
            }
        }
        &Expert {
            line-height: 0;
            vertical-align: text-bottom;
            &Title {
                font-size: .688em;
                letter-spacing: .08em;
                line-height: normal;
                text-align: center;
            }
            &Lastname {
                text-transform: uppercase;
                white-space: nowrap;
            }
            &Firstname,
            &Fullname {
                text-transform: capitalize;
                /*                 white-space: nowrap; */
            }
            &Firstname,
            &Lastname,
            &Fullname {
                color: rgb(var(--rouge-fonce));
                font-weight: 700;
            }
            &Spe {
                color: rgb(var(--gris-fonce));
            }
        }
    }
    &__date {
        color: rgb(var(--rouge-moyen));
        font-weight: 700;
    }
    &__spe {
        font-style: italic;
    }
}

.author {
    &__thumbnail {
        text-align: center;
    }
    &__name,
    &__spe {
        font-weight: 600;
    }
    &__name,
    &__spe,
    &__country {
        text-align: center;
        @media(min-width: 48em) {
            text-align: left;
        }
    }
    &__name {
        font-size: 1.25em;
    }
    &__spe {
        color: rgb(var(--rouge-moyen));
    }
    &__country {
        font-size: .9375em;
        text-transform: uppercase;
    }
    &__citation {
        font-size: 1.125em;
        font-weight: normal;
    }
    &__bio,
    &__diplome {
        font-size: .9375em;
    }
    &__title {
        &Videos,
        &Pub,
        &Rewards,
        &Twitter,
        &Futur,
        &Reseau {
            color: rgb(var(--rouge-moyen));
        }
        &Rewards {
            font-size: 1.063em;
        }
    }
    &__cursus {
        li {
            font-size: .9375em;
        }
    }
    .grid__itemExpert {
        line-height: normal;
    }
}

.pub {
    &__item {
        font-size: .875em;
    }
}

.event {
    &__date,
    &__city,
    &__link,
    &__spe {
        color: rgb(var(--gris-moyen));
    }
    &__title,
    &__date {
        font-weight: 600;
    }
    &__title {
        color: rgb(var(--rouge-moyen));
    }
}

.sidebar {
    &__title {
        color: rgb(var(--rouge-moyen));
        font-size: 1.063em;
    }
}

.picto {
    &__twitter {
        font-size: 2em;
    }
}

.footer {
    color: rgb(var(--blanc));
    font-size: .875em;
    &__menu {
        line-height: 2;
    }
    &__medflixs {
        &Social {
            &Link {
                text-align: center;
            }
        }
        &Baseline {
            font-size: .7em;
            @media(min-width: 48em) {
                font-size: 1.0625em;
            }
        }
        &Nav {
            line-height: 2;
            @media(min-width: 48em) {
                font-size: 1.0625em;
            }
        }
    }
    &__cherry {
        &Baseline {
            color: rgb(var(--rouge-fonce));
        }
    }
    &__copyright {
        font-size: .9em;
    }
}

.connexion-title {
    text-align: center;
}

.bt {
    color: white;
    font-size: .75rem;
    line-height: 1.75
}

.video {
    &__title {
        font-weight: 600;
    }
}

.description {
    &__title {
        color: rgb(var(--rouge-fonce));
        text-transform: uppercase;
    }
    &__text,
    &__info {
        font-size: .875em;
    }
    >div:last-child {
        color: var(--rouge-fonce);
        @media(min-width: 35em) {
            text-align: right;
        }
    }
}

.contact {
    &__title {
        color: rgb(var(--rouge-fonce));
        font-size: 1.0625em;
    }
}

.author-filters-container {
    font-size: .875em;
}