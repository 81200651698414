.fieldset {
    /* background-color: rgb(var(--beige)); */
    border: transparent;
    /*     border-radius: .25em; */
    box-sizing: border-box;
    /* box-shadow: .0625em .0625em .25em; */
    margin: 5em auto;
    max-width: 40em;
    padding: 1em;
    width: 80%;
    line-height: 2em;
    &__header {
        margin-bottom: 1em;
    }
    &__form {
        &Control {
            /*             border-radius: .5em; */
            box-sizing: border-box;
            height: 2.8em;
            margin: .5em 0 1em;
            padding: 0 .5em;
            width: 100%;
            /*             outline: none; */
        }
        &Control.error {
            border-color: red;
        }
        &Group {
            textarea {
                min-height: 8em;
            }
            /* select {
                height: 5em;
            } */
        }
        &Bt {
            margin: 1em auto;
        }
        @media(min-width:48em) {
            margin: 0 auto;
            width: 80%;
        }
    }
    &__btn {
        border: none;
        /*         border-radius: .5em; */
        margin: 1em auto;
        text-transform: uppercase;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: block;
        text-align: center;
        &Submit {
            background: linear-gradient(to right, rgb(var(--rouge-moyen)) 0%, rgb(var(--rouge-fonce)) 100%);
        }
        /* &Step {
            background-color: rgb(var(--bleu-moyen));
        } */
    }
    &__help {
        display: flex;
        justify-content: space-between;
        margin-top: 1em;
    }
    hr {
        margin: 0 auto;
        width: 20em;
    }
    &__step {
        display: none;
    }
    &__step.active {
        display: block;
        input[type="checkbox"] {
            vertical-align: baseline;
        }
    }
    &__head {
        text-align: center;
        margin: 30px 0;
        .title {
            max-width: 300px;
            width: 100%;
            border-bottom: solid 4px red;
            margin: 0 auto;
            padding-bottom: 10px;
        }
        .sub-title {
            color: red;
            margin-top: 10px;
        }
    }
    /* &__next-step{
        display: block;
        margin: 30px auto 0;
        background-color: rgb(32,94,171);
        text-align: center;
        padding: 9px 20px;
        width: 200px;
        color: white;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 18px;
    } */
    &__dots {
        text-align: center;
        margin-top: 20px;
        .dot {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-color: grey;
            border-radius: 12px;
            margin: 0 7px;
        }
        .dot.active {
            background-color: rgb(32, 94, 171);
            cursor: pointer;
        }
    }
    &__error {
        color: rgb(var(--rouge-moyen));
        font-style: italic;
        margin: .5em 0;
        text-align: center;
    }
    &--fullWidth {
        margin-top: 1em;
        max-width: none;
        width: 100%;
    }
}

.form {
    &__mentions {
        display: inline-block;
        font-size: .75em;
        line-height: normal;
        margin-left: 1em;
        max-width: 90%;
    }
    &-group {
        [type="checkbox"] {
            vertical-align: super;
        }
        /* MENTIONS LEGALES */
        &.mentions {
            display: table;
            margin-bottom: 1em;
            >* {
                display: table-cell;
            }
            input[type="checkbox"] {
                margin-right: .5em;
                vertical-align: bottom;
            }
        }
    }
}

.error {
    color: rgb(var(--rouge-moyen));
    font-style: italic;
    margin: .5em 0;
    text-align: center;
}

.account {
    margin: 0 auto .5em;
    max-width: 70%;
    &__label,
    &__item {
        flex-grow: 1;
        line-height: 2;
        max-width: 50%;
    }
    &__label {
        span {
            background-color: rgb(var(--gris-bleu-fonce));
            color: white;
            padding: .5em;
        }
    }
    &__item {
        input,
        select {
            width: 100%;
        }
    }
}


/* AUTOCOMPLETE HEADER SEARCH */

.ui-helper-hidden-accessible {
    display: none;
}

ul.ui-autocomplete.ui-front {
    overflow: hidden;
    background-color: white;
    width: 437px !important;
    max-height: 350px;
    overflow-y: scroll;
    z-index: 101;
    @media screen and (max-width: 450px) {
        max-width: 320px;
        left: 50% !important;
        transform: translateX(-50%);
    }
    .ui-menu-item {
        /*  border-bottom: 1px solid black;
        border-right: 1px solid black;
        border-left: 1px solid black;
        color: rgb(var(--rouge-moyen)); */
        background-color: white;
        cursor: pointer;
        font-size: .875em;
        padding: 6px 9px;
        transition: all 0.3s ease-in-out;
    }
    .ui-menu-item:hover {
        background-color: rgb(var(--rouge-moyen));
        color: white;
    }
}

.alert-login {
    padding: 1em;
    p {
        b {
            color: rgb(232, 78, 64);
            font-size: 2em;
            display: block;
        }
    }
}