.new-breadcrumb span{
    color: #959595;
    text-decoration: underline;
}
.new-breadcrumb a {
    color: #959595 !important;
    text-decoration: none;
}
.landing-wrapper h1 {
    color: #3799CF;
    font-size: 23px;
    font-weight: bold;
}
.left-section img {
    width: 300px;
    border-radius: 5px;
}
.intervenant .title {
    color: #3799CF;
    font-size: 12px;

}
.intervenant .subject {
    color: #959595;
    font-size: 10px
}
.intervenant-name {
    font-style: italic;
}
.right-section h4 {
    color: #3799CF;
    font-size: 14px;
}
.register-block{
    display:flex;
    justify-content:space-between;
}

.right-section hr{
    background-color: #d8d4d4;
    height: 1px;
    margin-top: 2px;
    margin-bottom: 2px;
}
.right-section .days{
    color: #3799CF;
    font-size: 12px;
    font-weight: bold;
}
.days-subscriber{
    display: flex;
    justify-content:space-between;
}
.subscriber{
    color: #959595;
    font-size: 12px
}
.event-description{
    color: #959595;
    font-size: 14px;
    margin-top: 10px;
}
.landing-wrapper .card{
    background-color:  #eeeded !important;
}