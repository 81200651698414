.main {
    &--fullHD {
        max-width: 120em;
    }
    @media(min-width: 48em) {
        &__content {
            &--inversed {
                order: 1;
            }
        }
        &__sidebar {
            margin-right: 0;
            margin-left: 1em;
        }
    }
    @media(min-width: 80em) {
        &__content {
            width: 80%;
        }
        &__sidebar {
            width: calc(20% - 1em);
        }
        .grid {
            padding-left: 2em;
            &__bloc {
                /* &--2col {
                    flex-flow: nowrap;

                    p {
                        font-size: .875em;
                        line-height: 1.5;
                        margin-bottom: 1em;
                        margin-right: 1em;
                        width: calc(50% - 1em);
                    }
                } */
                /* >*:not(:last-child) {
                    padding-right: 1em;
                } */
            }
            &__item {
                width: calc(100%/3 - 2.5em);
            }
        }
    }
}

.grid {
    &__banner {
        margin-bottom: 1em;
    }
    &__bloc {
        padding-top: 0;
    }
    &__item {
        height: 18.5em;
        overflow: hidden;
        div.grid__itemText {
            height: 4em;
            overflow: hidden;
            position: relative;
            &::after {
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, rgb(255, 255, 255) 100%);
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        &Text {
            margin: 1em 0;
        }
        &Img {
            align-items: center;
            display: flex;
            height: 9em;
            justify-content: center;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
    }
}

.sidebar {
    &__item {
        justify-content: flex-start;
    }
    &__title {
        background-color: rgb(var(--rouge-fonce));
        padding: .5em 1em;
        position: relative;
        text-align: center;
        a {
            color: white;
            font-size: .875em;
            font-weight: 700;
        }
        svg {
            width: 50%;
        }
    }
    &__img {
        flex: 0 0 auto;
        width: 30%;
    }
    &__pictos {
        flex-grow: 1;
    }
}