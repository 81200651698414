.grid {
    padding: 0 2em;
    &__interne {
        padding: 1em .5em;
        @media(min-width: 80em) {
            padding: 1em 0;
        }
    }
    @media(min-width:80em) {
        padding-left: 0;
    }
    &__header {
        &--flex {
            @media (min-width: 30em) {
                align-items: flex-end;
                display: flex;
                justify-content: space-between;
            }
        }
    }
    &__item {
        box-shadow: 0 0 .2em rgba(0, 0, 0, .5);
        transition: all 0.3s ease-in-out;
        >a:not(.grid__itemExpert) {
            display: block;
            min-height: 3em;
            position: relative;
            text-align: center;
            width: 100%;
            img {
                height: 7em;
            }
            &.grid__itemExpert {
                display: table;
                position: relative;
            }
            &::before {
                background-color: rgba(0, 0, 0, .1);
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
                transition: all 0.3s ease-in-out;
            }
        }
        img {
            color: rgb(var(--gris-clair));
            font-style: italic;
            width: auto;
        }
        .grid__itemExpertThumb {
            display: table-cell;
            /* &::before {
                background-color: rgba(0, 0, 0, .3);
                content:"";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                transition: all 0.3s ease-in-out;
                width: 100%;
            } */
            img {
                max-height: none;
                min-height: 0;
                width: 9.375em;
            }
        }
        &:hover {
            box-shadow: 0 .05em .1em rgba(0, 0, 0, .5);
            >a:not(.grid__itemExpert)::before {
                background-color: transparent;
            }
        }
        &Detail {
            /* background: linear-gradient(to bottom, rgb(var(--blanc)) 0%, rgb(var(--gris-clair)) 200%); */
            padding: .5em;
            /*             li:nth-child(1), li:nth-child(2) { */
            /*                 min-height: 2.5em; */
            /*             } */
            &::after {
                clear: both;
                content: "";
                display: table;
            }
        }
        &Title,
        &Text {
            /*             margin-bottom: .5em; */
        }
        &Title {
            background-color: rgb(var(--rouge-fonce));
            /*             border-top-left-radius: .5em; */
            /*             border-top-right-radius: .5em; */
            height: 3em;
            padding: .5em 1em;
            position: relative;
            a, p {
                display: block;
                height: 100%;
                width: 100%;
                &::after {
                    background: linear-gradient(to right, rgba(231, 17, 30, 0) 50%, rgb(195, 11, 22) 100%);
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 50%;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        }
        &Description {
            margin: 1em 0;
            pre {
                display: block;
                word-break: break-all;
                white-space: inherit;
            }
        }
        &Social {
            float: right;
            &Link {
                background: resolve(icon-linkedin-author.svg) center no-repeat;
                display: inline-block;
                height: 2em;
                width: 2em;
            }
        }
        &Time {
            background-color: black;
            /*             border-radius: .5em; */
            border: .125em solid white;
            bottom: 1em;
            color: white;
            padding: 0 .5em;
            position: absolute;
            right: 1em;
        }
        .grid__itemExpertTitle {
            background-color: rgb(var(--beige));
            display: table-cell;
            padding: 0 .5em;
            vertical-align: middle;
            width: 65%;
        }
    }
    &__noItem {
        margin-top: 1em;
    }
    &__social {
        display: inline-block;
        float: right;
        height: 2em;
        width: 2em;
        @media(min-width: 48em) {
            height: 3.5em;
            width: 3.5em;
        }
        &Linkedin {
            background: resolve(icon-linkedin-author.svg) center no-repeat;
        }
        span {
            display: inline-block;
            text-indent: -9999px;
        }
    }
    &__date {
        @media (min-width: 30em) {
            margin-left: 1em;
        }
    }
    &__dashboard {
        &Content {
            background-color: rgb(var(--beige));
            padding: 1em;
            text-align: center;
            @media(min-width: 60em) {
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: middle;
            }
        }
        &Number {
            color: rgb(var(--background-menu-secondaire));
            font-size: 4em;
            font-weight: 700;
        }
        &Text {}
        &Left {
            @media(min-width: 60em) {
                width: calc(65% - 1em);
            }
        }
        &Right {
            margin: auto;
            max-width: 10em;
            padding: 1em;
            @media(min-width: 60em) {
                padding-left: 2em;
            }
        }
        &Footer {
            background-color: rgb(var(--rouge-fonce));
            color: white;
            padding: 1em;
            text-align: center;
        }
    }
    &__playlist {
        &Title {
            align-items: baseline;
            display: flex;
        }
        &Content {
            background-color: rgb(var(--beige));
            display: flex;
            justify-content: center;
            padding: 1em;
            position: relative;
            &::after {
                content: "";
                background: resolve(picto-video.svg) center no-repeat;
                height: 50%;
                position: absolute;
                top: 25%;
                width: 100%;
            }
        }
        &Footer {
            background-color: white;
            padding: 1em;
            text-align: center;
        }
    }
    /* &__bloc{
        &.authors{
            .grid__item{
                a{
                    &::before{
                        border-top-left-radius: .5em;
                        border-top-right-radius: .5em;
                    }
                }
            }

            .grid__itemTitle{
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: .5em;
                border-bottom-right-radius: .5em;
            }
        }
    } */
    /*     &__spe { */
    /*         background: linear-gradient(to right, rgb(var(--gris-noir)) 0%, rgb(var(--gris-clair)) 100%); */
    /*     } */
}